<template>
    <div class="addsubscribe-main" :class="{'edit-main':isEdit}">
        <div class="from-container" v-if="!successShow">
            <div class="from-item">
                <p class="lable">{{$t("subscribe.patient")}}</p>
                <div class="item-sect sect-patient" @click="openSurveyor">
                    <p class="patientName">
                        <van-field v-model="fromData.patientName" readonly  :placeholder='$t("subscribe.pleasepatient")' />
                    </p>
                    <van-icon name="arrow icon" v-if="!isEdit"   />
                </div>
            </div>
            <div class="from-item">
                <p class="lable">{{$t("subscribe.subscribeTime")}}</p>
                <div class="item-sect sect-patient" @click="openTime">
                    <p class="patientName">
                        <van-field v-model="fromData.planInHospDt" readonly  :placeholder='$t("subscribe.pleaseSubscribeTime")' />
                    </p>
                    <van-icon name="arrow icon" v-if="!isEdit"   />
                </div>
            </div>
            <div class="from-item">
                <p class="lable">{{$t("subscribe.remake")}}</p>
                <div class="item-sect sect-patient descRemake" >
                    <van-field v-model="fromData.remark" type="textarea" :readonly="isEdit"  rows="5" :placeholder='$t("subscribe.pleaseRemake")' />
                </div>
            </div>
            <div class="from-item" v-if="isEdit? uploadimg.length != 0: true">
                <p class="lable">{{$t("subscribe.photo")}}</p>
                <div class="item-sect sect-patient uploadimgStyle" >
                    <van-uploader class="lable" accept="image/*" v-model="uploadimg" :multiple="true" :max-count="10000" :class="{'isedituoloader':isEdit}">
                        <div class="uploadbox">
                            <van-icon name="plus" />
                        </div>
                    </van-uploader>
                </div>
            </div>
            <div class="submit-btn " v-if="!isEdit" :class="{'submit-disable':!submitdisable}" @click="submit">
                {{$t("subscribe.immediatelySubscribe")}}
            </div>
            <div class="submit-btn cancle-btn" v-else  @click="cancle">
                {{$t("subscribe.cancle")}}
            </div>
        </div>
        <div class="from-container success-container" v-else>
            <img src="@/assets/img/hosp-subscribe-success-icon.png" >
            <p class="sucess-tips">{{$t("subscribe.successTips")}}</p>
            <p class="desc">{{$t("subscribe.successDesc",{name:this.fromData.patientName})}}</p>
            <div class="btn" @click="$router.go(-1)">{{$t("subscribe.returnbtn")}}</div>
        </div>
        <my-overlay :show="appointmentTimeShow" :showField="'appointmentTimeShow'">
           <div slot="overBox" class="reply-over">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirm"
                @cancel="appointmentTimeShow = false"
            />
           </div>
        </my-overlay>
    </div>
</template>

<script>
import { patientQuery } from "@/api/patient";
import { InhospitalAdd, InhospitalGet, InhospitalCancel } from "@/api/index";
import myOverlay from "@/components/myoverlay"
import { getLocalDateTime } from "@/utils/date";
export default {
    components:{myOverlay},
    data () {
        return {
            appointmentTimeShow:false,
            fromData:{
                patientId:"",
                patientName:"",
                planInHospDt:"",
                remark:"",
            },
            currentDate:null,
            successShow:false,
            uploadimg:[],
            isEdit:false,
            getLocalDateTime,
            minDate:new Date(),
            maxDate:new Date(new Date().getFullYear()+11,1,1)
        }
    },
    computed:{
		getters(){
			return this.$store.getters
		},
        submitdisable(){
            return (!this.fromData.patientId?false:true)&&(!this.fromData.planInHospDt?false:true);
        }
	},
    watch:{
        'getters':{
			handler(news,old){
				// this.patientInfo = this.$store.getters.patientData.find(item => item.id  == this.$store.getters.selectPatientId);
                if(this.$store.getters.patientSelectIndex != null){
                    let res = this.$store.getters.patientData[this.$store.getters.patientSelectIndex];
                    this.fromData.patientId = res.id;
                    this.fromData.patientName = res.name;
					this.$store.commit("user/SET_PATIENTINDEX",null);
				}
			},
			deep:true
		},
        
    },
    destroyed () {
        this.$store.commit("user/SET_PATIENTINDEX",null);
    },
    created () {
        this.getPatientData();
        if(this.$route.query.yuyueZhuyuanId){
            this.isEdit = true;
            this.getDetail();
            document.title = this.$t("subscribe.subscruveDetail");
        }else{
            document.title = this.$t("subscribe.createdSubscribe");
        }
    },
    methods:{
        
        openSurveyor(){
            if(this.isEdit){
                return;
            }
            if(this.fromData.patientId){
                let index = this.$store.getters.patientData.findIndex(item => item.id  == this.fromData.patientId);
                this.$store.commit("user/SET_PATIENTINDEX",index);
            }
            this.$store.commit("user/SET_SENDPATIENT",true);

        },
        openTime(){
            if(this.isEdit){
                return
            }
            this.appointmentTimeShow = true;
        },
        onConfirm(){
            this.fromData.planInHospDt = getLocalDateTime(this.currentDate);
            this.appointmentTimeShow = false;
        },
        cancle(){
            const toast = this.$Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            InhospitalCancel({
                yuyueZhuyuanId:this.$route.query.yuyueZhuyuanId
            }).then(res=>{
                toast.clear()
                if(res.data.errorCode == 0){
                    this.$router.go(-1);
                    this.$toast(this.$t("comonTxt.operationsuccess"))
                }else{
                    this.$toast(this.$t("comonTxt.operationfail"))
                }
            })
        },
        
        getDetail(){
            const toast = this.$Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            InhospitalGet({
                yuyueZhuyuanId:this.$route.query.yuyueZhuyuanId
            }).then(res=>{
                toast.clear()
                if(res.data.errorCode == 0){
                    this.currentDate = new Date(res.data.data.planInHospDt);
                    res.data.data.planInHospDt = getLocalDateTime(res.data.data.planInHospDt);
                    this.fromData = res.data.data;

                    if(res.data.data.imgUrls){
                        for (let i = 0; i < res.data.data.imgUrls.length; i++) {
                            const it = res.data.data.imgUrls[i];
                            this.uploadimg.push({
                                url:it
                            })                                    
                        }
                    }
                }
            })
        },
        // 获取就诊人数据
		getPatientData(){
			
			patientQuery({}).then(res=>{
				if(res.data.errorCode == 0){
					if(res.data.data.length !== 0){                        
                        let result = res.data.data.find(item => item.id == this.$route.query.patientId);
                        if(result){
                            this.fromData.patientId = result.id;
                            this.fromData.patientName = result.name;
                        }
						this.$store.commit('user/SET_PATIENTDATA',res.data.data);
					}                    
				}else{
					this.$Toast(this.$t("patient.patientfail"))
				}
			})
		},
        async  uploadOss(index){
            if(!this.uploadimg[index].url){
                let result = await this.$uploadAxios.post('upload/base64-upload', {data:this.uploadimg[index].content,fileName:this.uploadimg[index].file.name});
                if(result.errorCode == 0){
                    this.uploadimg[index].imgUrl = result.data;
                }
            }
            if(index < (this.uploadimg.length-1)){
                index++;
                await this.uploadOss(index)
            }
        },
        async submit(){
            if(!this.submitdisable){
                return;
            }
            const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
            let datadd = {...this.fromData};
            if(this.uploadimg.length!=0){
                await this.uploadOss(0);
                let imgUrls = [];
                for(let it in this.uploadimg){
                    imgUrls.push(this.uploadimg[it].imgUrl||this.uploadimg[it].url);
                }
                datadd.imgUrls = imgUrls;
            }
            
            InhospitalAdd(datadd).then(res=>{
                toast.clear();
                if(res.data.errorCode == 0){
                    this.successShow = true;
                }else{
                    this.$toast(res.data.errorMsg)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.addsubscribe-main{
    min-height: 100vh;
    padding-top: 4rem;
    padding-bottom: 0.6rem;
    box-sizing: border-box;
    background: url("~@/assets/img/hosp-subscribe-banner.png") no-repeat;
    background-color: #5CD2FF;
    background-size: auto 7.76rem;
    background-position-y: -2rem;
    .from-container{
        margin: 0 0.64rem;
        padding: 1rem;
        background: #fff;
        border-radius: 0.52rem;
        .from-item{
            margin-bottom: 0.8rem;
            .lable{
                font-size: 0.72rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 0.4rem;
            }
            .sect-patient{
                height: 1.6rem;
                border: 0.04rem solid #ECEEF3;
                border-radius: 0.16rem;
                display: flex;
                align-items: center;
                overflow: hidden;
                justify-content: center;
                .patientName{
                    flex: 1;
                    padding-left: 0.4rem;
                    /deep/ .van-field__control{
                        font-size: 0.56rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #A8A8AB;
                    }
                }
                .van-icon {
                    flex: 0 0 auto;
                    font-size: 0.6rem;
                    margin-right: 0.6rem;
                }
            }
            .descRemake{
                height: auto;
                /deep/ .van-field{
                    padding: 0.5rem;
                    background: #ECEEF3;
                    .van-field__control{
                        font-size: 0.56rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #A8A8AB;
                    }  
                } 
            }
            .uploadimgStyle{
                border: 0;
                height: auto;
                display: block;
                /deep/ .van-uploader{
                    .van-uploader__input-wrapper, .van-uploader__preview .van-image, .van-uploader__preview .van-uploader__file {
                        width: 3.6rem;
                        height: 3.6rem;
                        background: #ECEEF3;
                        border-radius: 0.2rem;
                        
                    }
                    .van-uploader__preview{
                        &:nth-child(3n+3){
                            margin-right: 0;
                        }
                        .van-uploader__preview-delete{
                            height: 0.6rem;
                            width: 0.6rem;
                            border-top-right-radius: 0.2rem;
                           .van-icon {
                               font-size: 0.6rem;
                           } 
                        }
                        .van-image {
                            // border-radius: 0.3rem;
                            overflow: hidden;
                        }
                    }
                    .van-uploader__input-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .van-icon{
                            font-size: 1.5rem;
                            font-weight: bold;
                            margin-right: 0;
                        }
                    }
                }
                /deep/ .isedituoloader{
                     .van-uploader__input-wrapper{
                        display: none;
                    }
                    .van-uploader__preview-delete{
                        display: none;
                    }
                }
            }
        }
        .submit-btn{
            width: 100%;
            color: #fff;
            height: 2rem;
            background: linear-gradient(0deg, #0095FF 0%, #6EC9FF 100%);
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 500;
        }
        .submit-disable{
            background: #ECEEF3;
            color: #ccc;
        }
        .cancle-btn{
            margin: 1.5rem 0 1rem 0;
        }
    }
    .success-container{
        text-align: center;
        img{
            display: block;
            width: 5.36rem;
            height: 4.86rem;
            margin: 2rem auto 1rem auto;
        }
        .sucess-tips{
            font-size: 0.96rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .desc{
            font-size: 0.64rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            width: 80%;
            margin: 0.4rem auto 2rem auto;
        }
        .btn{
            height: 2rem;
            background: linear-gradient(0deg, #0095FF 0%, #68C6FF 100%);
            border-radius: 1rem;
            line-height: 2rem;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 3.5rem;
        }
    }
}
.edit-main{
    .patientName, .descRemake{
        color: #333333;
        /deep/ .van-field__control{
            color: #333333 !important;
        }
    }
}
</style>